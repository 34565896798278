import React, { useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { MDBInput } from "mdb-react-ui-kit";

import axios from "axios";

function Forgotform() {
  const email = useRef();
  const password = useRef();
  const navigate = useNavigate();

  const FormHandle = async (e) => {
    if (counter < 0) {
      counter++;
    } else {
      document.getElementById("loginbtn").disabled = true;
    }
    e.preventDefault();
    const email_val = email.current.value;
    const password_val = password.current.value;
    if (!email_val || !password_val) {
      document.getElementById("loginbtn").disabled = false;
      return alert("Enter both Phone and Password");
    }
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_DEVELOPMENT_URL}/password`,
        {
          password: password_val,
          phone: email_val,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      alert(data.msg);
      document.getElementById("loginbtn").disabled = false;
      navigate({
        pathname: "/Login",
      });
    } catch (error) {
      alert(error.response.data.msg);
      document.getElementById("loginbtn").disabled = false;
    }
  };
  const scroll = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  var counter = 0;
  return (
    <>
      <span id="topbutton" onClick={scroll}>
        {""}
      </span>

      <div className=" login">
        <div className="navhead">
          <div className="title">
            <NavLink
              className="navbar-brand"
              id="brandname"
              to="/"
              data-aos="flip-left"
              data-aos-anchor="#example-anchor"
              data-aos-offset="500"
              data-aos-duration="500"
              data-aos-delay="500"
            >
              <img
                src="wlogo.png"
                alt=""
                id="wlogo"
                style={{ marginLeft: "30px", width: "180px" }}
              />
            </NavLink>
          </div>
        </div>
        <div className="nborder"></div>

        <div className="logform">
          <div className="formbox">
            <form onSubmit={FormHandle}>
              <h1 className="pb-3" style={{ fontSize: "26px" }}>
                Reset Password!
              </h1>
              <p>Phone no</p>
              <MDBInput
                className="mb-3"
                type="text"
                placeholder="Enter account phone number"
                id="form1Example1"
                ref={email}
              />
              <p>New Password</p>
              <MDBInput
                className="mb-4"
                type="password"
                id="form1Example1"
                placeholder="Create new password"
                ref={password}
              />
              <div className="lbtn">
                <button
                  type="submit"
                  class="btn btn-primary "
                  data-mdb-ripple-init
                  id="loginbtn"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Forgotform;
